var searchKeys = [{
  key: "date",
  label: "日期范围[UTC]",
  format: "YYYY-MM-DD",
  attrs: {
    showTime: false
  },
  placeholder: ["开始时间", "结束时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };