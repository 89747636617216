var columns = [{
  title: '用户ID',
  dataIndex: 'uid',
  key: 'uid',
  width: '13%',
  scopedSlots: {
    customRender: 'uid'
  }
}, {
  title: '账号昵称',
  dataIndex: 'username',
  key: 'username',
  width: '13%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '账号邮箱',
  dataIndex: 'email',
  key: 'email',
  width: '15%',
  scopedSlots: {
    customRender: 'email'
  }
}, {
  title: '设备ID',
  dataIndex: 'deviceid',
  key: 'deviceid',
  width: '15%',
  scopedSlots: {
    customRender: 'deviceid'
  }
}, {
  title: 'IP地址',
  dataIndex: 'ipaddress',
  key: 'ipaddress',
  width: '15%',
  scopedSlots: {
    customRender: 'ipaddress'
  }
}, {
  title: '系统平台',
  dataIndex: 'os',
  key: 'os',
  width: '10%',
  sorter: false,
  scopedSlots: {
    customRender: 'os'
  }
}, {
  title: '机型',
  dataIndex: 'model',
  key: 'model',
  width: '10%',
  scopedSlots: {
    customRender: 'model'
  }
}, {
  title: '系统版本',
  dataIndex: 'edition',
  key: 'edition',
  width: '15%',
  scopedSlots: {
    customRender: 'edition'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '120px',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };